<mat-toolbar color="primary">
  <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with menu icon"
  ></button>
  <span>Chill - SalesOps</span>
  <span class="example-spacer"></span>
  <button
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
  >
    <mat-icon>search</mat-icon>
  </button>
  <button
    mat-icon-button
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
  >
    <mat-icon>event</mat-icon>
  </button>
  <button
    mat-icon-button
    class="example-icon"
    aria-label="Example icon-button with share icon"
  >
    <mat-icon>info</mat-icon>
  </button>
</mat-toolbar>
