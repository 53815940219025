export const environment = {
  production: false,
  clientId: '11363cd7-04d6-46c2-b5a2-66da6d524740',
  tenantId: 'd145d1f9-d198-4bfc-963d-1ebccb89af5b',
  baseAddressAPI: 'https://uat-salesfunction.azurewebsites.net',
  postLogoutUrl: 'https://uat-sales.chill.ie',
  code: 'kPo33mkIh2PWRHtDtPE0wJDlgx3FGTQE3p4PnyRePwR61kyQ07IteQ==',
  onBoardingDocuments: {
    address: 'https://uat-onboardingfunctions.azurewebsites.net',
    code: 'n74c3hBIw7wpXwShh3qGZz381B80GlDyomG2xy0rjDslAzFucRRqTw==',
  },
  apiManagement: {
    address: 'https://apim-chill-motor-dev.azure-api.net/gs/',
    scopes: ['api://efca2a33-e30a-40c4-ab2d-895a78ad656b/.default'],
    subscriptionKey: '0623f68bf27e4f93aabb70e3683a3117'
  },
  onBoardingAPI: {
    address: 'https://dev-api.chill.ie',
    subscriptionKey: 'f1a68d74173240cb857cbd7c281b5544',
  },
};
