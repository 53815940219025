import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sales } from 'src/app/model/sales.model';
import { DataService } from 'src/app/services/data.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-client-code-update',
  templateUrl: './client-code-update.component.html',
  styleUrls: ['./client-code-update.component.css'],
})
export class ClientCodeUpdateComponent {
  @Input() sales: Sales;

  public clientCode: string;
  options: FormGroup;
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto');

  constructor(
    public dialogRef: MatDialogRef<ClientCodeUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public salesParameter: Sales,
    private data: DataService,
    private authService: MsalService,
    fb: FormBuilder
  ) {
    this.sales = this.sales;
    this.options = fb.group({
      hideRequired: this.hideRequiredControl,
      floatLabel: this.floatLabelControl,
    });
  }

  updateClientCode() {
    let status: any = {
      orderId:
        this.salesParameter.payment.timeStamp +
        '-' +
        this.salesParameter.payment.reference,
      userName: this.authService.instance.getAllAccounts()[0]?.username,
      clientCode: this.clientCode,
      appId: 12,
    };

    this.data.updateClientCode(status);

    this.dialogRef.close({ clientCode: this.clientCode });
  }
}
