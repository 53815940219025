import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { Insurer } from 'src/app/model/insurer.model';
import { RasStatus } from 'src/app/model/rasStatus.model';
import { Sales } from 'src/app/model/sales.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-update-sales-info',
  templateUrl: './update-sales-info.component.html',
  styleUrls: ['./update-sales-info.component.css'],
})
export class UpdateSalesInfoComponent implements OnInit {
  @Input() public sales: Sales;

  form: FormGroup;
  insurer = '';
  price = 0;
  insurerList: Insurer[] = [];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UpdateSalesInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public saleParameter: Sales,
    private authService: MsalService,
    private data: DataService
  ) {
    this.sales = saleParameter;
    this.form = this.fb.group({
      insurer: ['', Validators.required],
      price: ['', Validators.required],
    });
    this.setValues();
  }

  ngOnInit(): void {
    this.data.getAllInsurers().subscribe((res) => {
      this.insurerList = res;
    });
  }

  setValues() {
    this.form.setValue({
      insurer: this.sales.payment.insurerId.toString(),
      price: this.sales.quoteSummaries.displayedPremium,
    });
  }

  updateSaleDetail() {
    if (this.form.valid) {
      console.log(this.form.value);
    } else {
      return;
    }
  }

  onFormSubmit() {
    let selectedInsurer = this.insurerList.filter(
      (x) => x.code === this.form.get('insurer').value
    );

    let status: RasStatus = {
      id: 0,
      timestamp: new Date(),
      orderId: this.sales.payment.timeStamp
        .concat('-')
        .concat(this.sales.payment.reference),
      userName: this.authService.instance.getAllAccounts[0]?.username,
      clientCode: this.sales.clientCode,
      appId: 12,
      companyName: selectedInsurer[0].insurerLegalName,
      telephoneNumber: this.sales.quoteQueries.contactMobile,
      insurerId: selectedInsurer[0].code,
      email: this.sales.quoteQueries.contactEmail,
      amount: this.form.get('price').value,
    };

    this.data.UpdateRasStatus(status);

    this.dialogRef.close({ status });
  }
}
